import { LandingPage } from "~graphql/sdk";

import { useEventsService } from "~features/events/hooks/useEventsService";
import Tours from "~form/tours/toursOverview";
import SingleEventPage from "./event";
import Events from "./events";
import Overall from "./overall";
import { Status } from "~components";
import { useOrganization } from "~hooks";
import CalendarHomePage from "./calendar";

const Home = () => {
  const { organization, hasFeature } = useOrganization();

  const { events, isLoading, error } = useEventsService();

  let PageComponent = Events;
  if (hasFeature("eventCalendarHomepage")) {
    PageComponent = CalendarHomePage;
  } else if (organization.landingPage === LandingPage.SingleEvent) {
    PageComponent = events?.length === 1 ? SingleEventPage : Overall;
  } else if (organization.landingPage === LandingPage.Tours) {
    PageComponent = Tours;
  }

  return (
    <Status loading={isLoading} error={error}>
      <PageComponent />
    </Status>
  );
};

export default Home;
