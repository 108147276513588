import React from 'react';

import { Flex, Text, Container } from 'flicket-ui';

import { Layout, ContentContainer, Footer } from '~components';

export const EmptyState = () => (
  <Layout layoutType="main">
    <ContentContainer variant="content">
      <Container
        innerProps={{
          minHeight: { _: 0, md: '408px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: { _: 4, md: 0 },
        }}
        mb={{ _: 0, md: 10 }}
        flex="1"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flex={1}
          maxW={{ _: 'calc(100% - 32px)', md: '556px' }}
          mx={'auto' as any}
        >
          <Text
            fontSize={{ _: 3, md: 6 }}
            fontWeight="demiBold"
            textAlign="center"
            mb={{ _: 0, md: 1 }}
          >
            There are no events currently available
          </Text>
          <Text
            fontSize={3}
            lineHeight="medium"
            color="N600"
            textAlign="center"
            maxW="75%"
          >
            There are currently no tickets for events on sale. Your account and
            transaction history can be accessed at all times.
          </Text>
        </Flex>
      </Container>
      <Footer />
    </ContentContainer>
  </Layout>
);
