import React, { useContext } from "react";

import { Flex, Container } from "flicket-ui";
import useSWR from "swr";

import {
  Footer,
  BottomBar,
  Menu,
  Seo,
  Status,
  ContentContainer,
} from "~components";
import { BaseContext } from "~context";
import { sdk } from "~lib";
import { Layout } from "~components/common/Layout";
import { remainingEventsForSeason } from "~lib/helpers/remainingEventsForSeason";
import { TourOverviewTile } from "~components/tours/tourOverviewTile";
import sortBy from "lodash/sortBy";

const getSeasons = async (_: string, orgId: string) => {
  const seasons = (await sdk({ orgId }).seasons()).seasons;
  const sortedZones = sortBy(seasons, (season) => season.displayOrder ?? 0);
  return sortedZones;
};

const swrConfig = {
  shouldRetryOnError: false,
};

const Tours = () => {
  const { organization } = useContext(BaseContext);
  const { data: seasons, error: seasonsError } = useSWR(
    organization?.id ? ["seasonsOverview", organization?.id] : null,
    getSeasons,
    swrConfig
  );

  return (
    <>
      <Seo title="Tours" description="Tours" />
      <Menu />
      <Layout layoutType="main">
        <ContentContainer variant="content">
          <Flex flexDir="column" flex="1">
            <Container
              innerProps={{ minHeight: { _: 0, md: "320px" } }}
              mb={10}
              mt={10}
            >
              <Flex flexWrap="wrap" m={"-12px" as any} pb="6/4">
                <Status
                  noResult={!seasons}
                  noResultText="No events on sale just, check back soon."
                  error={seasonsError}
                  loading={!seasonsError && !seasons}
                >
                  {!!seasons &&
                    seasons?.map(
                      ({
                        id,
                        name,
                        events,
                        startDate,
                        endDate,
                        thumbnail,
                        isActive,
                        description,
                      }) =>
                        isActive &&
                        remainingEventsForSeason(events).length > 0 && (
                          <TourOverviewTile
                            id={id}
                            name={name}
                            events={events}
                            startDate={startDate}
                            endDate={endDate}
                            thumbnail={thumbnail}
                            description={description}
                          />
                        )
                    )}
                </Status>
              </Flex>
            </Container>
          </Flex>
          <Footer />
        </ContentContainer>
      </Layout>
      <BottomBar />
    </>
  );
};

export default Tours;
