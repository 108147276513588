import { SeasonsQuery } from "~graphql/sdk";

export const remainingEventsForSeason = (
  events: SeasonsQuery["seasons"][number]["events"]
) => {
  const now = new Date();
  const remaining = events.filter(
    (e) => new Date(e.startDate) > now && e.isActive
  );

  return remaining;
};
