import { Footer, Menu, PageContainer, Seo } from "~components";

import { Layout } from "~components/common/Layout";
import { EventsCalendar } from "~components/calendar/calendar";
import { useRouter } from "next/router";

const CalendarHomePage = () => {
  const router = useRouter();

  function handleSelectEvent(eventId: string) {
    void router.push({
      pathname: `/events/[eventId]/reservation`,
      query: {
        ...router.query,
        eventId: eventId,
      },
    });
  }

  return (
    <>
      <Seo title="Events calendar" />

      <Layout
        layoutType="main"
        css={`
          padding-top: 0 !important;
        `}
      >
        <Menu sticky={false} />

        <PageContainer
          backgroundImage={null}
          backgroundImageFullPage={null}
          bannerImage={null}
          titleText={null}
          footer={<Footer />}
          title={null}
          containerProps={{
            pt: 3,
            minHeight: { _: 0, md: "320px" },
          }}
        >
          <EventsCalendar handleSelectEvent={handleSelectEvent} />
        </PageContainer>
      </Layout>
    </>
  );
};

export default CalendarHomePage;
